import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChargesService } from '../../../services/charges.service';
import { OneAuthService, OneNotifyService } from 'one-auth';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProcurantChargesOverlayContainerComponent } from '../procurant-charges-overlay-container/procurant-charges-overlay-container.component';
import { isNullOrUndefined } from 'is-what';

@Component({
  selector: 'charges-overlay-list',
  templateUrl: './charges-overlay-list.component.html',
  styleUrls: ['./charges-overlay-list.component.scss']
})
export class ChargesOverlayListComponent implements OnInit {
  editchargesForm: FormGroup;
  modalData: any;
  applyByCode = 'FLAT';
  isEdit: boolean = false;
  isShowProrateToLine = true;
  @Input() data: any;
  poStatus: boolean = false;
  poId: number;
  poLineId: number;
  chargeAddEdit: number = 1; // 1. Add 2. Edit
  charges: any[];
  chargeAPPLY_BY: any[];
  chargePRORATE_BY: any[];
  isSave: boolean = false;
  isEditChargeExist = 0;
  addedBy: string;
  chargeFlag: boolean = false;
  isEditForm: boolean = false;
  isHideAddCharges: boolean;
  tariffRateError : boolean =false;

  @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  permissions: boolean = true;
  editFlag: boolean = true;

  constructor(public auth: OneAuthService, @Inject(MAT_DIALOG_DATA) public dialogData, private fb: FormBuilder, private cdr: ChangeDetectorRef, private service: ChargesService, private chargesOverlayContainer: ProcurantChargesOverlayContainerComponent, private notify: OneNotifyService) { }

  createChargeArray() {
    const decimalPatern = '^[0-9]+(\.[0-9]{1,4})?$';
    return this.fb.group({
      id: [],
      poId: [],
      poLineId: [],
      chargeId: [''],
      chargeName: [''],
      name: ['', Validators.compose([Validators.required])],
      applyOnModuleCode: [''],
      displayToAppCode: [''],
      companyName: [''],
      chargeAmt: ['', Validators.compose([Validators.pattern(decimalPatern), Validators.max(99999.9999), Validators.min(0.0001), Validators.required])],
      chargeTypeId: [],
      chargeTypeCode: [''],
      chargeCode: [''],
      applyByCode: [this.applyByCode, Validators.compose([Validators.required])],
      includedInLC: [],
      inclProdCost: [],
      prorateById: [''],
      prorateByCode: ['', this.isShowProrateToLine ? Validators.compose([Validators.required]) : null],
      addedBy: [''],
      freight: [],
      tariffRate: []
    });
  }

  setChargeArray(val) {
    const decimalPatern = '^[0-9]+(\.[0-9]{1,4})?$';
    let name = val.chargeName + ' | ' + val.chargeTypeCode + ((val.freight == true) ? ' - Freight' : '');
    let chrg = this.charges?.find(chrge => chrge.id == val.chargeId);
    return this.fb.group({
      id: [val.id],
      poId: [val.poId],
      poLineId: [val.poLineId],
      chargeId: [val.chargeId],
      chargeName: [val.chargeName],
      applyOnModuleCode: [val.applyOnModuleCode],
      displayToAppCode: [val.displayToAppCode],
      companyName: [val.companyName],
      displayToSupplier: [val.displayToSupplier],
      name: [name, Validators.compose([Validators.required])],
      chargeAmt: [val.chargeAmt, Validators.compose([Validators.pattern(decimalPatern), Validators.max(99999.9999), Validators.min(0), Validators.required])],
      chargeTypeId: [val.chargeTypeId],
      chargeTypeCode: [val.chargeTypeCode],
      chargeCode: [val.chargeCode],
      applyByCode: [val.applyByCode, Validators.compose([Validators.required])],
      includedInLC: [val.includedInLC],
      inclProdCost: [val.inclProdCost],
      prorateById: [val.prorateById],
      prorateByCode: [val.prorateByCode, this.isShowProrateToLine ? Validators.compose([Validators.required]) : null],
      addedBy: [val.addedBy],
      freight: [val.freight],
      tariffRate: [!val.hasOwnProperty("tariffRate") || isNullOrUndefined(val.tariffRate) ? null : val.tariffRate],
      isTariffEdit: [chrg?.isTariffEdit],
      adminTariffRate: [chrg?.tariffRate]
    });
  }

  setValidator(field): FormControl {
    var validatorArray = [];
    validatorArray.push(Validators.required);
    return this.fb.control('', validatorArray);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    if (this.data != null) {
      this.isShowProrateToLine = this.data[1];
      this.editchargesForm = this.fb.group({
        editChargesArray: this.fb.array([this.createChargeArray()]),
      });
      this.poStatus = this.data[2];
      if (this.data[0].objData[0].chargeName)
        this.isEditChargeExist = this.data[0].objData.length;
      this.setPolineids(this.data[0].objData);
      this.addedBy = this.data[3]
      this.getCharges().then(() => {
        this.loadOrderDetail();
      });
      this.getChargeSetting();
      if (this.data[3] == 'BUYER')
        this.permissions = this.data[4];
      else {
        this.isHideAddCharges = this.data[4];
      }
      this.loadOrderDetail();
    }
  }

  setPolineids(data) {
    if (data.length > 0) {
      if (this.isShowProrateToLine)
        this.poId = data[0].poId;
      else {
        this.poId = data[0].poId;
        this.poLineId = data[0].poLineId;
      }
    }
  }

  //(NM)PMB-10829 Used promise to method.
  getCharges(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.service.getCharges('ORDER', !this.isShowProrateToLine ? "Delivered" : this.data[0]?.routing)
        .subscribe(resp => {
          this.charges = resp;
          this.charges.forEach(element => {
            let name: string;
            element.chargeName = element.name;
            name = element.name + ' | ' + element.chargeTypecode + ((element.freight == true) ? ' - Freight' : '');
            element.name = name;
          });
          resolve(); 
        }, error => {
          reject(error); 
        });
    });
  }


  getChargeSetting() {
    this.service.getChargeSetting("APPLY_BY").subscribe(resp => {
      this.chargeAPPLY_BY = resp;
    })

    this.service.getChargeSetting("PRORATE_BY").subscribe(resp => {
      this.chargePRORATE_BY = resp;
    })
  }

  loadOrderDetail() {
    if (this.data) {
      //Clear all Control before loading it

      const control = <FormArray>this.editchargesForm.controls['editChargesArray'];
      if (control.controls) {
        control.controls = [];
      }
      if (this.data[0].objData[0].chargeName)
        this.isEditChargeExist = this.data[0].objData.length;
      if (this.data) {
        Object.keys(this.data[0].objData).forEach(index => {
          control.push(this.setChargeArray(this.data[0].objData[index]));
        });
        // if (this.isEditChargeExist > 0)
        //   this.setProrateByValidation();
      }
      if (this.data[3] == 'BUYER')
        this.permissions = this.data[4];
      if (this.isEditChargeExist > 0) {
        this.chargesOverlayContainer.addChargesFlag = false
      } else {
        this.chargesOverlayContainer.addChargesFlag = true;
        this.add_new();
      }
    }
  }

  setProrateByValidation() {
    if (this.isShowProrateToLine) {
      const controlEdit = <FormArray>this.editchargesForm.controls['editChargesArray'];
      for (let i in controlEdit) {
        controlEdit.at(+i).get('prorateByCode').setValidators(Validators.required);
        controlEdit.at(+i).get('prorateByCode').updateValueAndValidity();
      }
    } else {
      const controlEdit = <FormArray>this.editchargesForm.controls['editChargesArray'];
      for (let i in controlEdit) {
        controlEdit.at(+i).get('prorateByCode').setValidators(null);
        controlEdit.at(+i).get('prorateByCode').markAsUntouched();
        controlEdit.at(+i).get('prorateByCode').updateValueAndValidity();
      }
    }
  }

  public chargeSetValidator() {
    let chargesArray;
    let control;
    chargesArray = this.editchargesForm.controls['editChargesArray'].value;
    control = <FormArray>this.editchargesForm.controls['editChargesArray'];
    let j: number = 0;
    let invalid = false;
    if (control.length > 0) {
      for (let i in chargesArray) {
        if (this.isShowProrateToLine) {
          if (chargesArray[i].prorateByCode == '') {
            control.at(+i).get('prorateByCode').setErrors({ required: true })
            invalid = true;
          }
        }
        j++;
      }
    }
    return invalid;
  }


  setProratedBy(proratedBy: any): void {
    if (proratedBy) {
      let chrg = this.charges.find(chrge => chrge.name == proratedBy);
      this.editchargesForm.get('prorateByCode').setValue(chrg.id);
    }

  }

  submitCharges() {
    if (!this.chargeFlag) {
      this.chargeFlag = true;
      let chargeValue;
      if (!this.chargeSetValidator()) {
        if (this.chargeAddEdit == 1) {
          chargeValue = this.editchargesForm.get('editChargesArray').value;
          chargeValue.forEach(element => {
            element.poId = this.poId;
            element.changedPartyBy = this.data[3];
            if (!this.isShowProrateToLine)
              element.poLineId = this.poLineId;
          });

          if (this.isShowProrateToLine) {
            this.service.addUpdatePOHeaderCharges(chargeValue).subscribe(resp => {
              this.isSave = true;
              this.chargesOverlayContainer.submit();
            }, (err) => {
              this.chargeFlag = false;
              this.notify.show(err, 'error')
            })
          } else {
            this.service.addUpdatePOLinesCharges(chargeValue).subscribe(resp => {
              this.isSave = true;
              this.chargesOverlayContainer.submit();
            }, (err) => {
              this.chargeFlag = false;
              this.notify.show(err, 'error')
            })
          }
        }
      }
    }

  }

  cancel() {
    this.chargesOverlayContainer.addChargesFlag = false;
    this.chargesOverlayContainer.title = "CHARGE.LABEL.CHARGE_LIST";
    this.cdr.detectChanges();
  }

  add_new() {
    this.chargesOverlayContainer.addChargesFlag = true;
    this.chargesOverlayContainer.title = "CHARGE.LABEL.CHARGES";
    this.cdr.detectChanges();
  }

  changeValue(value, index) {
    let chrg = this.charges.find(chrge => chrge.id == value.chargeId);
    const control = this.editchargesForm.get('editChargesArray')['controls'][index];
    control.get('id').setValue(value.id);
    control.get('poId').setValue(value.poId);
    control.get('chargeId').setValue(value.chargeId);
    control.get('chargeName').setValue(value.chargeName);
    control.get('applyOnModuleCode').setValue(value.applyOnModuleCode);
    control.get('displayToAppCode').setValue(value.displayToAppCode);
    control.get('companyName').setValue(value.companyName);
    control.get('displayToSupplier').setValue(value.displayToSupplier);
    control.get('chargeAmt').setValue(value.chargeAmt);
    control.get('chargeTypeCode').setValue(value.chargeTypeCode);
    control.get('chargeCode').setValue(value.chargeCode);
    control.get('applyByCode').setValue(value.applyByCode);
    control.get('inclProdCost').setValue(value.inclProdCost);
    control.get('prorateById').setValue(value.prorateById);
    control.get('prorateByCode').setValue(value.prorateByCode);
    control.get('addedBy').setValue(value.addedBy);
    control.get('freight').setValue(value.freight);
    control.get('tariffRate').setValue(!value.hasOwnProperty("tariffRate") || isNullOrUndefined(value.tariffRate) ? null : value.tariffRate);
    // control.get('edited').setValue(true);
    this.editFlag = false;
    if(chrg.categoryCode == "49") {
      this.tariffRateError = value.tariffRate === "" || value.tariffRate > chrg.tariffRate;    //(NM) PMB-10829 Edit tariff Charge Implementation
    }
  }

  onDelete(order) {
    if (!this.chargeFlag) {
      this.chargeFlag = true;
      let chargeValue;
      chargeValue = order.value;
      chargeValue.poId = this.poId;
      chargeValue.changedPartyBy = this.data[3];
      chargeValue.chargeAmt = 0;
      chargeValue = [chargeValue];
      this.service.addUpdatePOHeaderCharges(chargeValue).subscribe(resp => {
        this.isSave = true;
        this.chargesOverlayContainer.submit();
      }, (err) => {
        this.chargeFlag = false;
        this.notify.show(err, 'error')
      })
    }
  }
}
