import { environment } from './environment';

export const URLS = Object();

//Jones: Add all API endpoints here
URLS.UserLogout = environment.BASE_API_URL + "auth/api/user/logout-internal";

// Messages API's
URLS.MessagesListAPI = environment.BASE_API_URL + "order/dashboard/buy" // "order/dashboard/sup";
URLS.MessagesPostAPI = environment.BASE_API_URL + ""; //TODO : Add API Endpoint when available
URLS.MessagesDetailAPI = environment.BASE_API_URL + "order/dashboard/details/";

URLS.GetMessageListAPI = environment.BASE_API_URL + "notification/api/message/get";
URLS.SetMessageAsReadAPI = environment.BASE_API_URL + "notification/api/message/read/";
URLS.DissmisMessageAPI = environment.BASE_API_URL + "notification/api/message/dismiss";

// User Activity API's
URLS.UserActivityListAPI = environment.BASE_API_URL + "notification/api/message/user-activity";

// Reminders API's
URLS.AddReminderAPI = environment.BASE_API_URL + "order/reminder/create";
URLS.ReminderDetailsAPI = environment.BASE_API_URL + "order/reminder/find/";
URLS.UpdateReminderAPI = environment.BASE_API_URL + "order/reminder/update";
URLS.GetReminderLIstAPI = environment.BASE_API_URL + "order/reminder/list";
URLS.DeleteReminderAPI = environment.BASE_API_URL + "order/reminder/remove";
URLS.EnableReminderAPI = environment.BASE_API_URL + "order/reminder/enable";
URLS.DeleteDismissReminderAPI = environment.BASE_API_URL + "order/reminder/remove";
URLS.GetProfileImage = environment.BASE_API_URL + "admin/api/object-store/view/"



// Logistics Service
URLS.TradingPartnerAPI = environment.BASE_API_URL + "admin/api/trading-partners/my-trading-partners/BUYER/CARRIER";
URLS.AssignedModelListAPI = environment.BASE_API_URL + "logistics/load/transmodes";
URLS.SearchOrderNumberAPI = environment.BASE_API_URL + "order/buy/search/suggest/";
URLS.UnassignedOrdersAPI = environment.BASE_API_URL + "order/buy/unloaded/orders/list";
URLS.UpdateLoadRefNumberAPI = environment.BASE_API_URL + "order/buy/po/update-loadRefNumber"; // (RS) PMB-8865 PMB-9246 new api url to update loadLink
URLS.GetLoadListAPI = environment.BASE_API_URL + "logistics/load/list";
URLS.GetLoadPOSAPI = environment.BASE_API_URL + "logistics/load/fetchLoadPos/";
URLS.SearchOrder = environment.BASE_API_URL + "order/buy/search/";

URLS.LoadCreateAPI = environment.BASE_API_URL + "logistics/load/create";
URLS.LoadUpdateAPI = environment.BASE_API_URL + "logistics/load/update";
URLS.LoadFindAPI = environment.BASE_API_URL + "logistics/load/find/";
URLS.LoadCancel = environment.BASE_API_URL + "logistics/load/cancel";
URLS.RemoveOrderFromLoad = environment.BASE_API_URL + "logistics/load/remove/loadPOs";
URLS.GetAllPOListAPI = environment.BASE_API_URL + "logistics/load/fetchAllPos";   // (RS)PMB-7704 new api to fetch all po's for load on load list when click on expand all button.

URLS.GetOrderCommentCount = environment.BASE_API_URL + "order/comment/count/PO";
URLS.GetOrderDetails = environment.BASE_API_URL + "order/po/short/";
URLS.SuggestLoadNumber = environment.BASE_API_URL + "logistics/load/search/suggest/";
URLS.SearchLoad = environment.BASE_API_URL + "logistics/load/search/";
URLS.GetOrgDetails = environment.BASE_API_URL + "admin/api/org/overview/";
URLS.GetOrgsDetails = environment.BASE_API_URL + "admin/api/org/getOrganizationShortDetails";
URLS.GetOrgListDetails = environment.BASE_API_URL + "admin/api/org/getBizUnitShortDetails";
URLS.GetChargeSettings = environment.BASE_API_URL + "order/po/charge/setting/";
URLS.GetCharges = environment.BASE_API_URL + "admin/api/charge/list";
URLS.GetBreakupCharges = environment.BASE_API_URL + "admin/api/charge/list/freight-breakup-charges";
URLS.GetLoadChargeDetails = environment.BASE_API_URL + "logistics/load/charge/";
URLS.AddUpdateLoadCharges = environment.BASE_API_URL + "logistics/load/charge";
URLS.SuggestUnloadedOrdersListAPI = environment.BASE_API_URL + "order/buy/serach/suggest-unloaded-orders-list"; //On add orders to load
URLS.SuggestUnloadedOrdersAPI = environment.BASE_API_URL + "order/buy/search/suggest-unloaded-orders"; //On create load
URLS.ExactSearchUnloadedOrdersAPI = environment.BASE_API_URL + "order/buy/exactSearch/unloaded-orders-list/";
URLS.AddPOrderToLoadAPI = environment.BASE_API_URL + "logistics/load/add-porder-to-load";
URLS.ExistingLoadToMoveOrderAPI = environment.BASE_API_URL + "logistics/load/move-po/exists-load-list/";
URLS.MoveOrderToLoadAPI = environment.BASE_API_URL + "logistics/load/move-po";
URLS.LoadNumberExistsAPI = environment.BASE_API_URL + "logistics/load/exists-by-loadNumber/";
URLS.LoadNumberGenerateAPI = environment.BASE_API_URL + "logistics/loadNumber/generate";
URLS.LoadNumberReleaseAPI = environment.BASE_API_URL + "logistics/loadNumber/release";
URLS.GetLoadPOChargeDetails = environment.BASE_API_URL + "logistics/load/loadPo/charge/";
URLS.AddUpdateLoadPOCharges = environment.BASE_API_URL + "logistics/load/loadPo/charge";
URLS.GetLoadChargeApplyByAPI = environment.BASE_API_URL + "logistics/load/load-charge-apply-by";
URLS.GetLogisticsAppModuleAccessAPI = environment.BASE_API_URL + "logistics/load/businessRules";
URLS.GetLatestCommentAPI = environment.BASE_API_URL + "order/comment/get-latest-comment";

//Zone service
URLS.ZoneListAPI = environment.BASE_API_URL + "logistics/load/shipping-zone-list";
URLS.GetStateListAPI = environment.BASE_API_URL + "admin/api/address/public/state-list/";
URLS.CreateZoneAPI = environment.BASE_API_URL + "logistics/load/create-shipping-zone";
URLS.GetZoneDetailsAPI = environment.BASE_API_URL + "logistics/load/fetch-client-shipping-zone/";
URLS.UpdateZoneAPI = environment.BASE_API_URL + "logistics/load/update-shipping-zone";

// Order Service
URLS.GetSupplierListAPI = environment.BASE_API_URL + "admin/api/trading-partners/my-trading-partners/BUYER/SUPPLIER";
URLS.GetOnlineOfflineSupplierListAPI = environment.BASE_API_URL + "admin/api/trading-partners/get-online-offline-suppliers/BUYER/SUPPLIER";
URLS.GetSupplierCodesAPI = environment.BASE_API_URL + "admin/api/org/buyer/supplier-codes/3";
URLS.GetSupplierCodesOfSupplier = environment.BASE_API_URL + "admin/api/org/buyer/supplier-codes/";
URLS.ShipsFromBusinessUnitAPI = environment.BASE_API_URL + "admin/api/org/businessUnits/";
URLS.ShipsToBusinessUnitAPI = environment.BASE_API_URL + "admin/api/org/businessUnits/DELIVERY";
URLS.crossDockBusinessUnitAPI = environment.BASE_API_URL + "admin/api/org/businessUnits/CROSS_DOCK";
URLS.DeliveryToShortBusinessUnitAPI = environment.BASE_API_URL + "admin/api/org/short/businessUnits/DELIVERY";
URLS.SearchProductCodeAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest-code-desc-active/";
URLS.GetProductDetailsAPI = environment.BASE_API_URL + "catalog/api/catalog/search-code-desc-active/";
URLS.POCreateAPI = environment.BASE_API_URL + "order/po/buyer/create";
URLS.DuplicatePOAPI = environment.BASE_API_URL + "order/buy/po/";
URLS.GetOrderAPI = environment.BASE_API_URL + "order/buy/list-new";
URLS.SearchOrderSuggestAPI = environment.BASE_API_URL + "order/buy/search/suggest/";
URLS.SearchOrderNuberAPI = environment.BASE_API_URL + "order/buy/search/";
URLS.GetCOOList = environment.BASE_API_URL + "catalog/api/catalog/origin";
URLS.SearchOrderByFiltersAPI = environment.BASE_API_URL + "order/buy/search/suggest/by-filter/";
URLS.BalanceItem = environment.BASE_API_URL + "order/buy/po-line/balance-item"
//(PM) PMB-9247 On the Order Details report need the ability to filter by Product code
URLS.SearchProductCodeByFiltersAPI1 = environment.BASE_API_URL + "order/buy/search/suggest-productcode/by-filter/";

URLS.SearchProductCodeByFiltersAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest-productcode/by-filter/";

URLS.UpdateOrderAPI = environment.BASE_API_URL + "order/buy/po/update";
URLS.UpdateOrderNewAPI = environment.BASE_API_URL + "order/buy/po/update-new";
URLS.UpdateOrderAll = environment.BASE_API_URL + "order/buy/po/update-inventory-pos";
URLS.GetPOOrderDetails = environment.BASE_API_URL + "order/buy/po/";
URLS.GetPOOrderHeaderDetails = environment.BASE_API_URL + "order/buy/po-header/";
URLS.GetPOHeaderIndicators = environment.BASE_API_URL + "order/buy/po-indicator/";
URLS.GetPOLineSummary = environment.BASE_API_URL + "order/po/po-total-summary/";
URLS.GetPOOrderLineDetails = environment.BASE_API_URL + "order/buy/po-line/"
URLS.GetPOOrderLineDetailsPaging = environment.BASE_API_URL + "order/buy/po-line-page"
URLS.GetLoadData = environment.BASE_API_URL + "logistics/load/find-by-po/";
URLS.OrderDetailsAPI = environment.BASE_API_URL + "catalog/api/catalog/fetchCatalogItemPrimary/";
URLS.GetOrderHistory = environment.BASE_API_URL + "order/buy/history/";
URLS.GetPoLineHistory = environment.BASE_API_URL + "order/buy/poline/history/";
URLS.PODraftCreateAPI = environment.BASE_API_URL + "order/po/buyer/createPODraft";
URLS.GetPODraftDetails = environment.BASE_API_URL + "order/buy/draft/";
URLS.DeleteDraftPOLine = environment.BASE_API_URL + "order/po/discardPODraftLine?id=";
URLS.DeleteDraftPOLines = environment.BASE_API_URL + "order/po/discardPODraftLine-new";
URLS.DeleteDraftPO = environment.BASE_API_URL + "order/po/discardPODraft?id=";
URLS.POHeaderCharges = environment.BASE_API_URL + "order/buy/header-charge/"
URLS.POLineCharges = environment.BASE_API_URL + "order/buy/line-charge/"
URLS.AddLineAPINew = environment.BASE_API_URL + "order/buy/add-line-new";
URLS.GetOrderGuideProductPrice = environment.BASE_API_URL + "catalog/api/product-template/order-guides-product-min-price/";



URLS.PONumberExistsAPI = environment.BASE_API_URL + "order/po/exists-by-ponumber/";
URLS.PONumberGenerateAPI = environment.BASE_API_URL + "order/ponumber/generate";
URLS.PONumberReleaseAPI = environment.BASE_API_URL + "order/ponumber/release";
URLS.GetExistingPOList = environment.BASE_API_URL + "order/buy/move-line/exists-order-list/";
URLS.PostMoveLine = environment.BASE_API_URL + "order/buy/move-line/";
URLS.PostMoveLineNewAPI = environment.BASE_API_URL + "order/buy/move-line-new";

URLS.AddLineAPI = environment.BASE_API_URL + "order/buy/add-line";
URLS.UpdateLineProduct = environment.BASE_API_URL + "order/buy/po/line-product/update";

URLS.AcceptPOLineItem = environment.BASE_API_URL + "order/dashboard/accept";
URLS.SendPOLineItem = environment.BASE_API_URL + "order/dashboard/send";

URLS.ChangeSupplier = environment.BASE_API_URL + "order/buy/change-supplier"
URLS.CheckParellelTransactions = environment.BASE_API_URL + "order/po/check-parellel-transactions";

URLS.AppModuleAccessAPI = environment.BASE_API_URL + "order/po/businessRules";
URLS.AppModuleBRAPI = environment.BASE_API_URL + "order/po/is-business-rule-granted";
URLS.GetTraceInfo = environment.BASE_API_URL + "order/sup/get-trace-info/false/";
URLS.CheckWarehouseAssociation = environment.BASE_API_URL + "catalog/api/catalog/check-catalog-warehouse-association";
URLS.CheckWarehouseAssociationCopyPO = environment.BASE_API_URL + "order/buy/validate/copy-po";
URLS.getSearchedProductDetails = environment.BASE_API_URL + "catalog/api/catalog/search-list-of-code-active";
URLS.CancelledPOMarkedAsViewedAPI = environment.BASE_API_URL + "order/po/flag-cancelled-po";
URLS.getPreferredBusinessUnitsDeliveryAPI = environment.BASE_API_URL + "admin/api/org/preferred-businessUnits/DELIVERY";
URLS.getSplitPoFlag = environment.BASE_API_URL + "order/buy/get-split-po-flag";
URLS.getSplitPo = environment.BASE_API_URL + "order/buy/split-po";
URLS.getSplitLinePo = environment.BASE_API_URL + "order/buy/split-poLine";
//(PM)PMB-7631 Add ability to default charge apply by and prorate by settings for a buying company
URLS.BRRules = "order/po/businessRules";



//Invoice Service
URLS.GetInvoiceDetails = environment.BASE_API_URL + "order/invoice/short/";
URLS.GetRemitToList = environment.BASE_API_URL + "admin/api/org/getOrganizationByBizUnit";
URLS.GetOrgsDetails = environment.BASE_API_URL + "admin/api/org/getOrganizationShortDetails";


//Invoice Charges
URLS.setInvoiceIndicatorAsViwedAPI = environment.BASE_API_URL + "payment/invoice/invoice-indicator/delete";

// OrderClaims Service
// URLS.SearchOrderNumber = environment.BASE_API_URL + "order/buy/search/suggest/"; using URLS.SearchOrderSuggestAPI
// URLS.GetOrders = environment.BASE_API_URL + "order/buy/list"; //using URLS.GetOrderAPI
URLS.GetClaimsAPI = environment.BASE_API_URL + "payment/claim/list";
URLS.CreateClaimAPI = environment.BASE_API_URL + "payment/claim/create";
URLS.UpdateClaimAPI = environment.BASE_API_URL + "payment/claim/update";
URLS.SuggestClaimNumberAPI = environment.BASE_API_URL + "payment/claim/search/suggest/";
URLS.SearchClaimNumberAPI = environment.BASE_API_URL + "payment/claim/search/";
URLS.ClaimDetailsAPI = environment.BASE_API_URL + "payment/claim/";
URLS.NonClaimedLinesAPI = environment.BASE_API_URL + "payment/claim/non-claim-lines/";
URLS.ClaimExistAPI = environment.BASE_API_URL + "payment/claim/exists/";
URLS.ApproveClaim = environment.BASE_API_URL + "payment/claim/approve";
URLS.UpdateClaimNotes = environment.BASE_API_URL + "payment/claim/update-claim-notes";
URLS.GetInvoiceHistory = environment.BASE_API_URL + "payment/invoice/history/";
URLS.GetInvoiceLineHistory = environment.BASE_API_URL + "payment/invoice/invoiceline/history/";
URLS.InvoiceHeaderCharges = environment.BASE_API_URL + "payment/invoice/header-charge/";
URLS.InvoiceLineCharges = environment.BASE_API_URL + "payment/invoice/line-charge/";
// URLS.SearchOrder = environment.BASE_API_URL + "order/buy/search/"; using URLS.SearchOrderNuberAPI
// URLS. = environment.BASE_API_URL +

//Demands Services
URLS.GetBuyerListFromUserDemand = environment.BASE_API_URL + "admin/api/user/buyerListForDemand";
URLS.GetCatalogListFromCommoditiesDemand = environment.BASE_API_URL + "catalog/api/catalog/catalogForUserDepartment?commodityIds=";
URLS.FetchCommoditiesFromDept = environment.BASE_API_URL + "catalog/api/catalog/fetch-commodities-for-depts";
URLS.CreateDemand = environment.BASE_API_URL + "order/demand/create";
URLS.GetDemandList = environment.BASE_API_URL + "order/demand/list"
URLS.GetUserDepartment = environment.BASE_API_URL + "admin/api/user/department/"
URLS.GetSendByListFromUserDemand = environment.BASE_API_URL + "admin/api/user/demandUserListForBuyer";
URLS.SearchProductCode = environment.BASE_API_URL + "order/demand/search/suggest/";
URLS.GetDemandDetail = environment.BASE_API_URL + "order/demand/demand-details-list";
URLS.UpdateDemand = environment.BASE_API_URL + "order/demand/update-demand-details";
URLS.CancelDemand = environment.BASE_API_URL + "order/demand/cancel-demand";
URLS.CreatePOValidate = environment.BASE_API_URL + "order/buy/validate/create-demands-po"
URLS.CreatePODemand = environment.BASE_API_URL + "order/buy/create/demands-po"
URLS.GetOrderGuideFromBuyerId = environment.BASE_API_URL + "catalog/api/product-template/share/list/"
URLS.GetProductByOrderGuide = environment.BASE_API_URL + "catalog/api/catalog/catalog-for-product-templates?productTemplateIds="
URLS.GetOrderGuideAndCommodity = environment.BASE_API_URL + "catalog/api/product-template/populate-dropdown-on-product-select-from/"
URLS.SetUnitCostDemand = environment.BASE_API_URL + "catalog/api/product-pricing/on-demand"
URLS.GetDemandIds = environment.BASE_API_URL + "order/demand/demand-ids";
URLS.GetGroupDemandList = environment.BASE_API_URL + "order/demand/list-non-aggregation";
URLS.GetDemandOrderInfo = environment.BASE_API_URL + "order/demand/order-details/";
URLS.GetDemandComments = environment.BASE_API_URL + "order/comment/list/"
URLS.GetGroupDemandProductList = environment.BASE_API_URL + "order/demand/demand-header-details-non-aggregation/"
URLS.GetDemandHeaderDetails = environment.BASE_API_URL + "order/demand/demand-headerdetails"
//(OL)PMB-6600 used to check redirection form orderDetails page

// Task Center Service
URLS.GetTabCount = environment.BASE_API_URL + "order/buy/taskcenter"
URLS.GetOrderTabCount = environment.BASE_API_URL + "order/buy/taskcenter?taskcenterTab="
//URLS.GetOrderHeaderAPI = environment.BASE_API_URL + "order/po/taskcenter/";
//URLS.GETOrderLineDetailsAPI = environment.BASE_API_URL + "order/po/taskcenter/";
URLS.SendAcceptAPI = environment.BASE_API_URL + "order/po/taskcenter/submit";
URLS.GetInvoiceListAPI = environment.BASE_API_URL + "payment/invoice/invoice-taskcenter";
URLS.GetOrderTaskCenterAPI = environment.BASE_API_URL + "order/po/taskcenter";
URLS.GetOrderModTaskCenterAPI = environment.BASE_API_URL + "order/po/taskcenter/";
URLS.GetUnloadedTaskCenterAPI = environment.BASE_API_URL + "order/po/taskcenter/unloaded-orders";
URLS.TriggerOffAPI = environment.BASE_API_URL + "logistics/load/task-center/trigger-off";
URLS.TaskcenterLoadCountAPI = environment.BASE_API_URL + "logistics/load/taskcenter-count-metadata?taskcenterTabs=";
URLS.GetDraftLoadAPI = environment.BASE_API_URL + "logistics/load/load-taskcenter";
URLS.TaskcenterInvoiceCountAPI = environment.BASE_API_URL + "payment/invoice/taskcenter-count-metadata?taskcenterTabs=";
URLS.TaskcenterInvoiceAPI = environment.BASE_API_URL + "payment/invoice/invoice-taskcenter";
URLS.GetFreightInvoiceListAPI = environment.BASE_API_URL + "logistics/freightInvoice/freightinvoice-taskcenter";

// core API service API's
URLS.ForgotPassword = environment.BASE_API_URL + "admin/api/user/public/forgot-password";
URLS.ValidateTokenAPI = environment.BASE_API_URL + "admin/api/user/public/validate-token/";
URLS.UpdateUserPasswordAPI = environment.BASE_API_URL + "admin/api/user/public/save-password";

URLS.GetReasonCodes = environment.BASE_API_URL + "admin/api/claims/get/active/reason-codes";

//My preferences
URLS.GetFetchNotifications = environment.BASE_API_URL + "admin/api/user/preferences/notifications/fetch/";
URLS.SaveEmailNotification = environment.BASE_API_URL + "admin/api/user/preferences/notifications/save-preferences";
URLS.GetLanguagesAPI = environment.BASE_API_URL + "admin/api/org/languages";
URLS.GetCurrencyAPI = environment.BASE_API_URL + "admin/api/org/currencies";
URLS.GetTimeZonesAPI = environment.BASE_API_URL + "admin/api/org/available-timezones";
URLS.GetProfileOverviewAPI = environment.BASE_API_URL + "admin/api/org/overview/";
URLS.GetOrderDefaultListAPI = environment.BASE_API_URL + "order/user-order-default/order-default-list";
URLS.GetOrderDefaultAPI = environment.BASE_API_URL + "order/user-order-default/order-default-details/";
URLS.SaveOrderDefaultAPI = environment.BASE_API_URL + "order/user-order-default/save";
URLS.UpdateOrderDefaultAPI = environment.BASE_API_URL + "order/user-order-default/update";
URLS.DeleteOrderDefaultAPI = environment.BASE_API_URL + "order/user-order-default/delete/";
URLS.GetOrderDefaultBySupplierAndDeliveryAPI = environment.BASE_API_URL + "order/user-order-default/order-default-details/";
URLS.PreferredDeliveryToShortBusinessUnitAPI = environment.BASE_API_URL + "admin/userWarehouse/list";
URLS.PreferredDeliveryLocAddUpdateAPI = environment.BASE_API_URL + "admin/userWarehouse/addUpdate";
URLS.PreferredDeliveryLocDeleteAPI = environment.BASE_API_URL + "admin/userWarehouse/remove/";

//Order and Load Report and Claim Report
URLS.GetOrderStatus = environment.BASE_API_URL + "order/po/active-order-statuses";
URLS.GetCommodities = environment.BASE_API_URL + "catalog/api/catalog/my-commodities";
URLS.GetBuyerListFromReport = environment.BASE_API_URL + "admin/api/user/list/";
URLS.SaveFilter = environment.BASE_API_URL + "admin/api/user-setting/save/filter";
URLS.GetFilterList = environment.BASE_API_URL + "admin/api/user-setting/get/filters/";
URLS.GetReportList = environment.BASE_API_URL + "order/api/reports/buy/order-details";
URLS.GetDownloadReport = environment.BASE_API_URL + "order/api/reports/buy/download/order-details";
URLS.GetLoadReportList = environment.BASE_API_URL + "logistics/api/reports/buy/load-totals";
URLS.GetDownloadLoadReport = environment.BASE_API_URL + "logistics/api/reports/buy/download/load-totals";
URLS.GetClaimStatus = environment.BASE_API_URL + "payment/claim/active-statuses";
URLS.GetResponsibleParties = environment.BASE_API_URL + "payment/claim/responsible-parties";
URLS.GetClaimReportList = environment.BASE_API_URL + "payment/api/reports/buy/claim-details";
URLS.GetDownloadClaimReport = environment.BASE_API_URL + "payment/api/reports/buy/download/claim-details";
URLS.GetSupplierFillRateReportList = environment.BASE_API_URL + "order/api/reports/supplier-fill-rate";
URLS.DownloadSupplierFillRateReport = environment.BASE_API_URL + "order/api/reports/download/supplier-fill-rate";

//My View on Create Load
URLS.GetCommoditiesAPI = environment.BASE_API_URL + "catalog/api/catalog/my-commodities";
URLS.SaveFilterAPI = environment.BASE_API_URL + "admin/api/user-setting/save/filter";
URLS.DeleteFilterAPI = environment.BASE_API_URL + "admin/api/user-setting/delete/filter";
URLS.GetFilterListAPI = environment.BASE_API_URL + "admin/api/user-setting/get/filters/";
URLS.GetLoadStatusListAPI = environment.BASE_API_URL + "logistics/load/statuses";
URLS.GetAllLoadStatusListAPI = environment.BASE_API_URL + "logistics/load/get-load-statuses";
URLS.GetShipFromAPI = environment.BASE_API_URL + "admin/api/org/trading-partner/businessUnits/";
URLS.UpdateUserProfile = environment.BASE_API_URL + "admin/api/user/updateUserProfile";
URLS.ChangeUserProfilePasswordAPI = environment.BASE_API_URL + "admin/api/user/change-password";
URLS.GetUser = environment.BASE_API_URL + "admin/api/user/fetchUser/";
URLS.GetUserProfile = environment.BASE_API_URL + "admin/api/user/me";
URLS.GetShipFromZoneAPI = environment.BASE_API_URL + "logistics/load/shipping-zone-drop-down";

URLS.GetShipFromNewAPI = environment.BASE_API_URL + "admin/api/org/trading-partner/businessUnits-new/";

//Supplier-Products(supplier_catalog) : (RS) PMB-9276 Create a new screen to show vendor item associations and vendor details
URLS.GetSupplierCatalogList= environment.BASE_API_URL + "catalog/api/catalog/fetch-supplier-catalog-list";
URLS.GetSupplierCatalog= environment.BASE_API_URL + "catalog/api/catalog/fetch-supplier-catalog";
URLS.ValidateSupplierCatalog= environment.BASE_API_URL + "catalog/api/catalog/validate-supplier-catalog";
URLS.AddUpdateSupplierCatalog= environment.BASE_API_URL + "catalog/api/catalog/supplier-catalog-add-update";
URLS.DeleteSupplierCatalog = environment.BASE_API_URL + "catalog/api/catalog/delete-supplier-catalog";
URLS.DeleteSupplierCatalogItem = environment.BASE_API_URL + "catalog/api/catalog/delete-supplier-catalog-item/";
URLS.DeleteSupplierCatalogItemList = environment.BASE_API_URL + "catalog/api/catalog/delete/supplier-catalog-item-list";
//pricing and Product Template
URLS.serchPriceList = environment.BASE_API_URL + ''
URLS.getUserComodities = environment.BASE_API_URL + 'catalog/api/catalog/my-commodities'
URLS.GetProductListByTemplateId = environment.BASE_API_URL + "catalog/api/product-template/item/list/";
URLS.deleteProductFromTemplate = environment.BASE_API_URL + "catalog/api/product-template/item/delete?ids="
URLS.suggestProductTemplate = environment.BASE_API_URL + "catalog/api/product-template/search/suggest/"
URLS.ListProductTemplate = environment.BASE_API_URL + "catalog/api/product-template/list"
URLS.UpdateProductTemplate = environment.BASE_API_URL + "catalog/api/product-template/update"
URLS.CreateProductTemplate = environment.BASE_API_URL + "catalog/api/product-template/create"
URLS.CopyProductTemplate = environment.BASE_API_URL + "catalog/api/product-template/copy-order-guide"
URLS.GetTemplateType = environment.BASE_API_URL + "catalog/api/product-template/template-type"
URLS.GetTemplateById = environment.BASE_API_URL + "catalog/api/product-template/"
URLS.GetMyDepartmentUsers = environment.BASE_API_URL + "admin/api/user/my-department-users"
URLS.updateTemplateStatus = environment.BASE_API_URL + "catalog/api/product-template/update-status"
URLS.TemplateNameExist = environment.BASE_API_URL + "catalog/api/product-template/name-exists/"
URLS.suggestTemplate = environment.BASE_API_URL + "catalog/api/product-template/search/suggest/";
URLS.GetMyDepartmentProducts = environment.BASE_API_URL + "catalog/api/catalog/search/suggest-my-catalog-code-desc/"
URLS.GetProductPriceListAPI = environment.BASE_API_URL + "catalog/api/product-pricing/orderguide/pricelist";
URLS.GetOrderGuideListAPI = environment.BASE_API_URL + "catalog/api/product-template/populate-dropdown-on-product-select-from/TEMPLATE";
URLS.CreatePriceListAPI = environment.BASE_API_URL + "catalog/api/product-pricing/orderguide/pricelist/create";
URLS.UpdatePriceListAPI = environment.BASE_API_URL + "catalog/api/product-pricing/orderguide/pricelist/update";
URLS.DeletePriceListAPI = environment.BASE_API_URL + "catalog/api/product-pricing/orderguide/pricelist/delete/";
URLS.RequestPricingAPI = environment.BASE_API_URL + "catalog/api/product-pricing/pricing-request/create"
URLS.GetPriceType = environment.BASE_API_URL + 'catalog/api/product-pricing/price-types';
URLS.getMyPricingDatesURL = environment.BASE_API_URL + 'catalog/api/product-pricing/orderguide/pricelist/my-pricing-dates';
URLS.getOrderGuidesURL = environment.BASE_API_URL + 'catalog/api/product-template/order-guides/';
URLS.DeleteOrderGuide = environment.BASE_API_URL + "catalog/api/product-template/delete/";
URLS.getBuyerPriceRequestList = environment.BASE_API_URL + 'catalog/api/product-pricing/price-requests-list-buy';
//(PM)PMB-8258 On Quick PO screen provide option (a new button) to pull pricing from Price sheets (request for pricing)
URLS.MarketPriceForQuickPo = environment.BASE_API_URL + "catalog/api/product-pricing/fecth-market-pricing-for-quick-po"
//(RP)PMB-8725: Add an ability to Inactivate / Hide pricing request for Buyers
URLS.UpdatePriceRequestStatus = environment.BASE_API_URL + "catalog/api/product-pricing/buyer/price-requests-details/update-status"
URLS.getOrderGuidesURLByUsersIds = environment.BASE_API_URL + "catalog/api/product-template/order-guides/ownerids";   //(PM) PMB-9820 Modifications to Buyer Order Details Report
URLS.ListProdTemplateForSupPricingReport = environment.BASE_API_URL + "catalog/api/product-template/get-order-guide-supplier-pricing";    //(PM) PMB-9822 Build a new Pricing report to allow user to export pricing information from the system
URLS.DownloadSupPricingReport = environment.BASE_API_URL + "catalog/api/product-pricing/download/supplier-pricing-report";     //(PM) PMB-9822 Build a new Pricing report to allow user to export pricing information from the system
URLS.pricingRequestMarkedAsViewedAPI = environment.BASE_API_URL + 'catalog/api/product-pricing/viewed/pricing-request/';

//route details
URLS.GetRouteDetailsAPI = environment.BASE_API_URL + "logistics/load/find-route/";

URLS.DownloadFile = environment.BASE_API_URL + "";
URLS.POLineFileUpload = environment.BASE_API_URL + "order/api/document/line/upload?id=";
URLS.POFileUpload = environment.BASE_API_URL + "order/api/document/po/upload?id=";
URLS.FetchPOLevelFiles = environment.BASE_API_URL + "order/api/document/po/all/";
URLS.FetchPOLineLevelFiles = environment.BASE_API_URL + "order/api/document/line/all/";
URLS.DeletePODocument = environment.BASE_API_URL + "order/api/document/delete?objectId=";
URLS.GetPOFileObjectDocument = environment.BASE_API_URL + "order/api/object-store/download/";
URLS.GetPOFileObjectViewAPI = environment.BASE_API_URL + "admin/api/object-store/view/";

//Claim Upload
URLS.ClaimFileUpload = environment.BASE_API_URL + "payment/api/document/claim/upload?id=";
URLS.FetchClaimLevelFiles = environment.BASE_API_URL + "payment/api/document/claim/all/"
URLS.DeleteClaimDocument = environment.BASE_API_URL + "payment/api/document/delete?objectId=";
URLS.GetClaimFileObjectDocument = environment.BASE_API_URL + "payment/api/object-store/download/";
URLS.GetClaimFileObjectViewAPI = environment.BASE_API_URL + "admin/api/object-store/view/";
URLS.GetClaimsCommentCount = environment.BASE_API_URL + "payment/comment/count/CLAIM";
//URLS.GetClaimHeaderIndicators = environment.BASE_API_URL + "payment/invoice/invoice-indicator/";
URLS.GetClaimHeaderIndicators = environment.BASE_API_URL + "payment/claim/claim-indicator/";

//Load Upload
URLS.LoadFileUpload = environment.BASE_API_URL + "logistics/api/document/load/upload?id=";
URLS.FetchLoadLevelFiles = environment.BASE_API_URL + "logistics/api/document/load/all/"
URLS.DeleteLoadDocument = environment.BASE_API_URL + "logistics/api/document/delete?objectId=";
URLS.GetLoadFileObjectDocument = environment.BASE_API_URL + "logistics/api/object-store/download/";
URLS.GetLoadFileObjectViewAPI = environment.BASE_API_URL + "admin/api/object-store/view/";
URLS.setLoadIndicatorAsViwedAPI = environment.BASE_API_URL + "logistics/load/indicator/delete";
URLS.GetLoadHeaderIndicators = environment.BASE_API_URL + "logistics/load/load-indicator/";

// watchlist
URLS.GetWatchList = environment.BASE_API_URL + "order/po/taskcenter/watchlist";
URLS.AddToWatchList = environment.BASE_API_URL + "order/po/saveUserPoFlag";
URLS.DeleteFromWatchList = environment.BASE_API_URL + "order/po/delete?";


//inventory
URLS.GetWeeks = environment.BASE_API_URL + "inventory/product-movement/weeks-list/";
URLS.GetDateDetails = environment.BASE_API_URL + "inventory/product-movement/week-data/";
URLS.GetTemplateOrCommodityList = environment.BASE_API_URL + "catalog/api/product-template/populate-active-product-templates";
URLS.FetchInventory = environment.BASE_API_URL + "inventory/product-movement/product-movement-list";
URLS.SaveInventory = environment.BASE_API_URL + "inventory/demand-planning/save-product-movement";
URLS.SetUnitCostInventory = environment.BASE_API_URL + "catalog/api/product-pricing/fecth-pricing-for-inventory"
URLS.GetInventoryDetail = environment.BASE_API_URL + "inventory/product-movement/inventory-product-details";
URLS.GetInventoryDetails = environment.BASE_API_URL + "inventory/demand-planning/inventory-product-details";

URLS.FetchInventoryNew = environment.BASE_API_URL + "inventory/demand-planning/product-movement-summary";
URLS.FetchProductInventory = environment.BASE_API_URL + "inventory/demand-planning/product-movement-detail";
URLS.FetchProcurantForecast = environment.BASE_API_URL + "inventory/demand-planning/get-procurant-forecast";
URLS.GetSupplierCatalogHeaderInfoAPI = environment.BASE_API_URL + "catalog/api/catalog/supplier-header-info";
URLS.GetSupplierCatalogProductAPI = environment.BASE_API_URL + "catalog/api/catalog/supplier-item";
// URLS.FetchProcurantForecast = environment.BASE_API_URL + "inventory/demand-planning/get-procurant-forecast-on-fly";
URLS.GetSafetyStock = environment.BASE_API_URL + "catalog/api/catalog/get-safety-stock";
URLS.SaveSafetyStock = environment.BASE_API_URL + "catalog/api/catalog/update-safety-stock";
URLS.GetOrderRestrictionTypeAPI = environment.BASE_API_URL + "catalog/api/catalog/get-order-restriction-type";

URLS.SaveRetailPrice = environment.BASE_API_URL + "inventory/demand-planning/save-weekly-retail";
URLS.SaveProductMovementSummary = environment.BASE_API_URL + "inventory/demand-planning/save-product-movement-summary";
URLS.GetInventoryPOIds = environment.BASE_API_URL + "inventory/demand-planning/";
URLS.GetInventoryPOsDetails = environment.BASE_API_URL + "order/inventory/inventory-order-list";
URLS.SaveProductDetails = environment.BASE_API_URL + "inventory/demand-planning/save-product-movement";
URLS.RecalculateInventoryData = environment.BASE_API_URL + "inventory/demand-planning/recalc-product-movement";
URLS.GetEvents = environment.BASE_API_URL + "inventory/demand-planning/events-for-week/";
URLS.SaveInventoryComment = environment.BASE_API_URL + "inventory/comment";
URLS.GetInventoryComment = environment.BASE_API_URL + "inventory/comment/list/"
URLS.FetchHistoricalGridData = environment.BASE_API_URL + "inventory/demand-planning-history/fetch-historical-grid-data"
URLS.FetchHistoricalHistogramData = environment.BASE_API_URL + "inventory/demand-planning-history/fetch-historical-histogram-data"
URLS.FetchTargetTimeSupply = environment.BASE_API_URL + "inventory/demand-planning/get-target-time-supply"


//Invoice Service
//URLS.GetInvoices = environment.BASE_API_URL + "payment/invoice/list"; Invoice list Old API
URLS.GetInvoices = environment.BASE_API_URL + "payment/invoice/list-new";
URLS.GetPaymentTerms = environment.BASE_API_URL + "admin/api/paymentterm/list";
URLS.SuggestInvoiceNumber = environment.BASE_API_URL + "payment/invoice/buy/search/suggest/";
URLS.SearchInvoice = environment.BASE_API_URL + "payment/invoice/buy/search-by-invoiceid/";
URLS.UpdateInvoice = environment.BASE_API_URL + "payment/invoice/update";
URLS.GetInvoiceDetails = environment.BASE_API_URL + "payment/invoice/";
URLS.GetInvoiceFormPoId = environment.BASE_API_URL + "payment/invoice/by-poid/";
URLS.InvoiceNumberExistsAPI = environment.BASE_API_URL + "payment/invoice/exists-by-invoiceNumber/"
URLS.PaymenttermDetailsById = environment.BASE_API_URL + "admin/api/paymentterm/";
URLS.ApproveInvoice = environment.BASE_API_URL + "payment/invoice/buy/update";
URLS.CancelInvoice = environment.BASE_API_URL + "payment/invoice/buy/update";


URLS.GetCountryListAPI = environment.BASE_API_URL + "admin/api/address/public/countries";
URLS.GetUserDepartmentListAPI = environment.BASE_API_URL + "admin/api/user/attributes/DEPARTMENT";

URLS.TriggerOffInvoiceAPI = environment.BASE_API_URL + "payment/invoice/task-center/trigger-off";

URLS.setIndicatorAsViwedAPI = environment.BASE_API_URL + "order/po/indicator/delete";




URLS.DownloadOrderGuideTemplate = environment.BASE_API_URL + "catalog/api/catalog/download-orderguide-template";
URLS.UploadOrderGuideTemplate = environment.BASE_API_URL + "catalog/api/catalog/upload-orderGuide";


// users
URLS.getAllUsers = environment.BASE_API_URL + "admin/api/user/buyers-user-list/ORDER_MANAGEMENT?selectedBuyersIds=";

URLS.GetLastShipFromLocationAPI = environment.BASE_API_URL + "order/po/fetch-last-ship-form";
URLS.GetLoadShipFrom = environment.BASE_API_URL + "order/po/find-shipFrom/";

//  Inpect-Item API's
URLS.RetrieveItemInspectionScore = environment.BASE_API_URL + "inspect/api/item/fetch-inspected-item";
URLS.SubmitItemInspectionscore = environment.BASE_API_URL + "inspect/api/item/inspect-item";
URLS.GetScoringCategories = environment.BASE_API_URL + "inspect/api/item/fetch-item-scoring-attributes";
URLS.GetInspectedOrderLines = environment.BASE_API_URL + "inspect/api/po/fetch-inspected-order-lines/";

//Rejection API's
//(RS)PMB-6641, PMB-PMB-6829 fetch-tolerances api updated
//URLS.FetchTolerances = environment.BASE_API_URL + "inspect/api/commodity/fetch-tolerances?commodityCode=";
URLS.FetchTolerances = environment.BASE_API_URL + "inspect/api/commodity/fetch-tolerances";
URLS.FetchDefects = environment.BASE_API_URL + "inspect/api/commodity/fetch-defects?commodityCode=";
URLS.FetchRejectedItem = environment.BASE_API_URL + "inspect/api/item/fetch-failed-item";
URLS.SubmitRejectItem = environment.BASE_API_URL + "inspect/api/item/reject-item";
URLS.GetLots = environment.BASE_API_URL + "order/inspection/get-trace-info/";
URLS.RejectLoad = environment.BASE_API_URL + "inspect/api/load/reject-load";
URLS.FetchLoadInspection = environment.BASE_API_URL + "inspect/api/load/fetch-load-inspection";
URLS.FetchLoadRejectionReasons = environment.BASE_API_URL + "inspect/api/load/fetch-load-rejection-reasons";
// FREIGHT INVOICE
URLS.getFreightInvoiceList = environment.BASE_API_URL + "logistics/freightInvoice/list";
URLS.FreightInvoiceSuggestInvoiceNumber = environment.BASE_API_URL + "logistics/freightInvoice/search/suggest/";
URLS.ApproveFreightInvoice = environment.BASE_API_URL + "logistics/freightInvoice/updateAndApproveInvoice";
URLS.ApproveListOfFreightInvoice = environment.BASE_API_URL + "logistics/freightInvoice/approve";
URLS.getFreightInvoiceHeader = environment.BASE_API_URL + "logistics/freightInvoice/invoice-header/";
URLS.LoadFindAPIByInvoiceId = environment.BASE_API_URL + "logistics/freightInvoice/find/";
URLS.getLoadDetailsSummary = environment.BASE_API_URL + "logistics/freightInvoice/load-total-summary/";
URLS.GetInvoiceHeaderIndicators = environment.BASE_API_URL + "logistics/freightInvoice/invoice-indicator/";
URLS.GetInvoiceHeaderCarge = environment.BASE_API_URL + "logistics/freightInvoice/charge/";
URLS.AddUpdateInvoiceCharge = environment.BASE_API_URL + "logistics/freightInvoice/charge/";
URLS.GetProductDetails = environment.BASE_API_URL + "catalog/api/catalog/get-active-catalog-ids";
URLS.GetCarrierCompanyList = environment.BASE_API_URL + 'admin/api/trading-partners/my-trading-partners/BUYER/CARRIER';

//Report
URLS.DownloadInvoiceReport = environment.BASE_API_URL + "payment/api/reports/buy/download/invoice-report";
URLS.GetInvoiceReport = environment.BASE_API_URL + "payment/api/reports/buy/invoice-report";
URLS.GetBuyerUser = environment.BASE_API_URL + "admin/api/user/buyers-user-list/ORDER_MANAGEMENT?selectedBuyersIds=";
URLS.GetSupplierPricingReport = environment.BASE_API_URL + 'catalog/api/product-pricing/supplier-pricing-list-report';   //(PM) PMB-9822 Build a new Pricing report to allow user to export pricing information from the system

//Tack and trace
URLS.TrackTraceSerach = environment.BASE_API_URL + "order/buy/product/search";
URLS.TrackTraceList = environment.BASE_API_URL + "order/buy/product/trace";
URLS.TrackTraceStoreShipment = environment.BASE_API_URL + "order/buy/store-shipments/trace"; //(HA)PMB-7682 Trace Screen
URLS.DownloadStoreShipment = environment.BASE_API_URL + "order/api/store-shipment/download-store-order-template";
URLS.UploadStoreShipmentsAPI = environment.BASE_API_URL + "order/api/store-shipment/upload-store-shipments";
URLS.GetStoreShipmentFile = environment.BASE_API_URL + "order/api/store-shipment/list";
URLS.SuggestStoreShipmentFile = environment.BASE_API_URL + "order/api/store-shipment/search/suggest/";
URLS.GetPriceRequestsDetails = environment.BASE_API_URL + 'catalog/api/product-pricing/buyer/price-requests-details';
URLS.UpdatePriceRequestDetails = environment.BASE_API_URL + 'catalog/api/product-pricing/buyer/price-requests-details/update';
URLS.GetTraceCommodities = environment.BASE_API_URL + "order/trace/mandatory-trace-data-commodies";
URLS.GetSupplierDashboardData = environment.BASE_API_URL + "order/trace/compliance/supplier-compliance-list";
URLS.GetCurrentMonthGraphData = environment.BASE_API_URL + "order/trace/compliance/get-month-compliance";
URLS.GetSupplierSixMonthDashboard = environment.BASE_API_URL + "order/trace/compliance/past-supplier-compliance";
URLS.GetCatalogDashboardData = environment.BASE_API_URL + "order/trace/compliance/commodity-compliance-list";
URLS.GetCatalogReport = environment.BASE_API_URL + "order/trace/compliance/commodity-compliance-report";
URLS.GetCommodityComplianceCount = environment.BASE_API_URL + "order/trace/compliance/commodity-compliance-count";
URLS.DownloadSupComplianceReport = environment.BASE_API_URL + "order/trace/compliance/download/supplier-compliance";
URLS.DownloadCommodityComplianceReport = environment.BASE_API_URL + "order/trace/compliance/download/commodity-compliance";


// Charges 
// Charges 
URLS.GetChargesListAPI = "admin/api/charge/list";
URLS.GetChargeSettingsAPI = "order/po/charge/setting/";
URLS.AddUpdatePOLineChargesAPI = "order/po/line/charge";
URLS.AddUpdatePOHeaderChargesAPI = "order/po/charge";
URLS.GetGITN = environment.BASE_API_URL + 'order/buy/search/suggest/gtins-from-pos';
URLS.GetLotNo = environment.BASE_API_URL + 'order/buy/search/suggest/lotNumbers-from-pos';
URLS.GetTraceReceveingReportList = environment.BASE_API_URL + 'order/api/reports/trace-receiving';
URLS.GetTraceReceveingDownload = environment.BASE_API_URL + 'order/api/reports/download/trace-receiving';
URLS.GetTraceShippingDownload = environment.BASE_API_URL + 'order/api/reports/download/trace-shipping';
// URLS.GetCatalogComment = environment.BASE_API_URL + 'catalog/comment/buyer/list/'
URLS.GetCatalogComment = environment.BASE_API_URL + 'catalog/comment/buyer/list/'
URLS.RequestPricingUpdateAPI = environment.BASE_API_URL + "catalog/api/product-pricing/pricing-request/update"
//(PM)PMB-9221 Allow to remove suppliers at item level on Pricing Request screen
URLS.PriceReqLineLevelRemoveSupplier = environment.BASE_API_URL + "catalog/api/product-pricing/remove/line-level-supplier"  

//Elastic Search  //(HA)PMB-7682 Trace Screen : load more store shipment on scroll
// URLS.getOrderListFromElasticSearch = environment.BASE_API_URL + "elasticsearch/search";
URLS.getPoListComments = environment.BASE_API_URL + "order/comment/get-po-list-comments"
URLS.GlobalSerachResult = environment.BASE_API_URL + 'search/facets';
URLS.ElasticSuggest = environment.BASE_API_URL + 'search/suggest';
URLS.getOrderListFromElasticSearch = environment.BASE_API_URL + "search/purchase-order/list";
URLS.SearchOrderByFiltersElasticAPI = environment.BASE_API_URL + "search/purchase-order/search/suggest/by-filter/";

URLS.getGITNForShipping = environment.BASE_API_URL + 'catalog/api/catalog/search/';
URLS.GetLotNoForShipping = environment.BASE_API_URL + 'order/buy/search/suggest/lotNumbers-from-store-shipments';
URLS.GetTraceShippingReportList = environment.BASE_API_URL + 'order/api/reports/trace-shipping';
URLS.GetTraceShippingDownload = environment.BASE_API_URL + 'order/api/reports/download/trace-shipping';
URLS.SuggestAvailableTradingPartnerAPI = environment.BASE_API_URL + "admin/api/trading-partners/search/suggest-trading-partners"; //(RS) PMB-8705 api url to search supplier

//Price Alerts
URLS.GetProductAlertsList = environment.BASE_API_URL + "analytics/api/alerts/price/alerts"
URLS.GetPriceAlertsWeeks = environment.BASE_API_URL + "analytics/api/inputs/weeks"
URLS.GetConfigProducts = environment.BASE_API_URL + "analytics/api/inputs/configured-products"
URLS.GetHistoricalPrice = environment.BASE_API_URL + "analytics/api/alerts/price/historical-price"
URLS.GetPriceAlertsReport = environment.BASE_API_URL + "analytics/api/alerts/price/report"
URLS.DownloadPriceAlertsReport = environment.BASE_API_URL + "analytics/api/alerts/price/download-report"
URLS.GetFileObjectDocument = environment.BASE_API_URL + "admin/api/object-store/download/";
