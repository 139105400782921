<app-toolbar [appName]="true" (appHomesClick)="eventclick($event)">
  <div class="logo" logo><img src="{{IMAGES.PROCURANT_LOGO}}"></div>
  <!-- (RD)PMB-6554 New header and navigation menu bar for Procurant Commerce -->
  <div product-name>
    Buyer
  </div>
  <span app-notifs class="header-icon icon-notification" [ngClass]="[headerFlag? 'pendingNotifications' : '']"
    [matMenuTriggerFor]="filterMenu" title="{{'BUTTON.REMINDERS'| translate}}" (click)="getRemindersList()"></span>

  <!-- //(HA)PMB-852 : Quick Order Search in Buyer and Supplier/Vendor Applications -->
  <div global-search *ngIf="true">
    <global-serach></global-serach>
  </div>



  <div>
    <mat-menu class="filterBox dashboardFilter" #filterMenu="matMenu">
      <div fxLayout="column" fxLayoutAlign="start start" (click)="$event.stopPropagation();">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="headerWithIcon mb-20">
          <div fxLayout="row" fxLayoutAlign="start center">
            <span class="icon-notification mr-15"></span>
            <h3 class="mr-10">{{'REMINDER.LABEL.ACTIVE_REMINDERS'| translate}}</h3>
          </div>
          <mat-slide-toggle [attr.data-target]="count" [checked]="dismissed===true ? true:false"
            (change)="onChange($event,0,'all')"
            [disabled]="auth?.user?.info?.internalAdmin || !auth?.user?.permissions?.BUYER?.MODULES?.ORDER_MANAGEMENT?.actions?.edit"
            *ngIf='!displayItem'>
          </mat-slide-toggle>
        </div>

        <div style="min-width: auto;max-height: 200px; overflow-y: auto;overflow-x: hidden;width: 100%;;" #scrollMe
          *ngIf='!displayItem && !displayEditMode && !noDataMessage'>
          <div *ngFor="let reminder of remindersList" class="fs-14 customItems" fxLayout="row"
            fxLayoutAlign="start stretch">
            <div style="width: 100%;padding: 10px 0px 10px 10px;"
              [ngStyle]="{'background-color': reminder.flag!=0 ? '#E8EAED': '#fff'}">
              <div class="reminderText" [ngClass]="!displayEditMode ? '':'reminderText mr-0 ml-0'">
                <div class="viewMode" *ngIf='!displayEditMode'>
                  <div>{{reminder.description}}</div>
                  <span class="statusTag greyTag">{{reminder.date}}</span>
                  <mat-slide-toggle
                    [disabled]="auth?.user?.info?.internalAdmin || !auth?.user?.permissions?.BUYER?.MODULES?.ORDER_MANAGEMENT?.actions?.edit"
                    [checked]="reminder.dismissed===false ? true:false" (change)="onChange($event,reminder.id)">
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" *ngIf="noDataMessage && !displayItem">
        {{noDataMessage}}
      </div>
    </mat-menu>
  </div>

  <div (click)="openProcurantAsk()" procurant-ask class="procurant-ask" matTooltip="Procurant Ask">
    <!-- Procurant-Ask -->
    <div class="logo">
      <img class="logo-image" id="logo-image" src="../../../../assets/images/procurant_logo.png" alt="Procurant Ask">
      <div class="stars">
        <svg class="star1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 25 25" fill="none">
          <path
            d="M16.6637 9.60318C16.8444 9.65039 17.0213 9.69385 17.1927 9.73378C17.0213 9.77372 16.8444 9.81718 16.6637 9.86439C15.1767 10.2529 13.3494 10.9156 12.2467 12.0184C11.1439 13.1212 10.4812 14.9484 10.0927 16.4354C10.0455 16.6161 10.002 16.793 9.96205 16.9645C9.92212 16.793 9.87866 16.6161 9.83145 16.4354C9.44292 14.9484 8.78022 13.1212 7.67745 12.0184C6.57467 10.9156 4.74741 10.2529 3.2604 9.86439C3.0797 9.81718 2.90281 9.77372 2.73139 9.73378C2.90281 9.69385 3.0797 9.65039 3.2604 9.60318C4.74741 9.21465 6.57467 8.55195 7.67745 7.44918C8.78022 6.3464 9.44292 4.51914 9.83145 3.03213C9.87866 2.85143 9.92212 2.67454 9.96205 2.50312C10.002 2.67454 10.0455 2.85143 10.0927 3.03213C10.4812 4.51914 11.1439 6.3464 12.2467 7.44918C13.3494 8.55195 15.1767 9.21465 16.6637 9.60318Z"
            fill="#87C440" stroke="#447703" stroke-width="1" />
        </svg>
        <svg class="star2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 25 25" fill="none">
          <path
            d="M16.6637 9.60318C16.8444 9.65039 17.0213 9.69385 17.1927 9.73378C17.0213 9.77372 16.8444 9.81718 16.6637 9.86439C15.1767 10.2529 13.3494 10.9156 12.2467 12.0184C11.1439 13.1212 10.4812 14.9484 10.0927 16.4354C10.0455 16.6161 10.002 16.793 9.96205 16.9645C9.92212 16.793 9.87866 16.6161 9.83145 16.4354C9.44292 14.9484 8.78022 13.1212 7.67745 12.0184C6.57467 10.9156 4.74741 10.2529 3.2604 9.86439C3.0797 9.81718 2.90281 9.77372 2.73139 9.73378C2.90281 9.69385 3.0797 9.65039 3.2604 9.60318C4.74741 9.21465 6.57467 8.55195 7.67745 7.44918C8.78022 6.3464 9.44292 4.51914 9.83145 3.03213C9.87866 2.85143 9.92212 2.67454 9.96205 2.50312C10.002 2.67454 10.0455 2.85143 10.0927 3.03213C10.4812 4.51914 11.1439 6.3464 12.2467 7.44918C13.3494 8.55195 15.1767 9.21465 16.6637 9.60318Z"
            fill="#87C440" stroke="#447703" stroke-width="1" />
        </svg>
        <svg class="star3" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 25 25" fill="none">
          <path
            d="M16.6637 9.60318C16.8444 9.65039 17.0213 9.69385 17.1927 9.73378C17.0213 9.77372 16.8444 9.81718 16.6637 9.86439C15.1767 10.2529 13.3494 10.9156 12.2467 12.0184C11.1439 13.1212 10.4812 14.9484 10.0927 16.4354C10.0455 16.6161 10.002 16.793 9.96205 16.9645C9.92212 16.793 9.87866 16.6161 9.83145 16.4354C9.44292 14.9484 8.78022 13.1212 7.67745 12.0184C6.57467 10.9156 4.74741 10.2529 3.2604 9.86439C3.0797 9.81718 2.90281 9.77372 2.73139 9.73378C2.90281 9.69385 3.0797 9.65039 3.2604 9.60318C4.74741 9.21465 6.57467 8.55195 7.67745 7.44918C8.78022 6.3464 9.44292 4.51914 9.83145 3.03213C9.87866 2.85143 9.92212 2.67454 9.96205 2.50312C10.002 2.67454 10.0455 2.85143 10.0927 3.03213C10.4812 4.51914 11.1439 6.3464 12.2467 7.44918C13.3494 8.55195 15.1767 9.21465 16.6637 9.60318Z"
            fill="#87C440" stroke="#447703" stroke-width="1" />
        </svg>
      </div>
      <!-- <div class="app-name"><span>Procurant Ask</span></div> -->
    </div>
    <div class="ask-label">Ask</div>
    
  </div>
  <!-- <span app-notifs class="header-icon icon-chat_message"></span> -->
  <!-- <span userimage><img [src]="(user?.info?.profileimagelink)?user.info.profileimagelink:IMAGES.DEFAULTPROFILE" /></span> -->
  <span userimage>
    <img *ngIf="auth?.thumbnil" [src]="auth?.thumbnil" router="UserProfileModule" alt="" title="" class="img">
    <div *ngIf="!auth?.thumbnil" class="img-avtar">
      <span>{{avtarName}}</span>
    </div>
  </span>
  <!-- <span username matTooltip="{{user?.info?.firstName}} {{user?.info?.lastName}}">
    {{user?.info?.firstName}} {{user?.info?.lastName}}
  </span> -->

  <!-- single column -->
  <!-- <div username class="user-profile">
    <div>
      <img [src]="auth?.thumbnil ? auth?.thumbnil : 'assets/images/profile.png'" router="UserProfileModule" alt=""
        title="" class="img">
    </div>
    <div>
      <span class="user-name">
        {{user?.info?.firstName}} {{user?.info?.lastName}}
      </span>
    </div>
  </div> -->

  <!-- double column -->
  <div username class="double-user-profile">
    <div>
      <img *ngIf="auth?.thumbnil" [src]="auth?.thumbnil" router="UserProfileModule" alt="" title="" class="img">
      <div *ngIf="!auth?.thumbnil" class="img-avtar">
        <span>{{avtarName}}</span>
      </div>
    </div>
    <div>
      <span class="user-name">
        {{user?.info?.firstName}} {{user?.info?.lastName}}
      </span>
    </div>
  </div>

  <div menu-options>

    <!-- <span username matTooltip="{{user?.info?.firstName}} {{user?.info?.lastName}}">
      {{user?.info?.firstName}} {{user?.info?.lastName}}
    </span> -->
    <button class="logoutBtn" mat-menu-item (click)="logout()">
      {{ 'BUTTON.LOGOUT' | translate }}
    </button>
    <button *ngIf="auth?.user?.info?.internalAdmin" mat-menu-item (click)="returnToInternalAdmin()">
      {{ 'BUTTON.RETURN_INTERNAL_ADMIN' | translate }}
    </button>
  </div>
</app-toolbar>