import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from 'common-lib';
import { OneAuthService } from 'one-auth';
import { AppService } from '@procurant/app/app.service';

@Component({
  selector: 'side-toolbar',
  templateUrl: './side-toolbar.component.html',
  styleUrls: ['./side-toolbar.component.scss']
})
export class SideToolbarComponent implements OnInit {

  sideNav = [];
  navigationScrollButton = "none";
  // buyerUserMenus: any = [
  //   { title: this.translate.instant("NAVBAR.LABEL.DASHBOARD"), route: '/dashboard', img: 'icon-dashboard' },
  //   { title: this.translate.instant("NAVBAR.LABEL.TASK_CENTER"), route: '/task-center/task-center-list', img: 'icon-task_centre' }
  // ];
  constructor(private route: ActivatedRoute, public auth: OneAuthService, private router: Router, private translate: TranslateService, private cdr: ChangeDetectorRef, public appService :AppService) { }
  public permissions: any;
  ngOnInit() {
    this.permissions = this.auth.user.permissions;
  }
  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.setNavigationScrollButton();
  }

  //PMB-6554(RD) add on scroll button flag
  scrollFunction() {
    if (this.auth.user.info.internalAdmin)
      var sidebar = document.getElementById('sidebarInternalAdmin');
    else
      var sidebar = document.getElementById('sidebar');
    this.navigationScrollButton = (sidebar.scrollTop > 20) ? ((sidebar.scrollHeight - sidebar.scrollTop - sidebar.clientHeight - 60 >= 1) ? "both" : "up") : "down";
    // this.scrollPostion=(sidebar.scrollHeight - sidebar.scrollTop - sidebar.clientHeight-60 >= 1) && (sidebar.scrollTop > 20);
  }

  //PMB-6554(RD) check scroll exits or not
  setNavigationScrollButton() {
    if (this.auth.user.info.internalAdmin)
      var sidebar = document.getElementById('sidebarInternalAdmin');
    else
      var sidebar = document.getElementById('sidebar');
    this.navigationScrollButton = (sidebar.scrollHeight > sidebar.clientHeight) ? "down" : "none";

  }

  //PMB-6554(RD) set flag for up and down buttons
  handelScrollClick(direction) {
    if (this.auth.user.info.internalAdmin)
      var sidebar = document.getElementById('sidebarInternalAdmin');
    else
      var sidebar = document.getElementById('sidebar');
    sidebar.scrollTop = direction == "up" ? 0 : sidebar.clientHeight;
  }


}
