import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderService } from '../../services/order.service';
import { OneAuthService } from 'one-auth';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'call-charges-overlay',
  templateUrl: './call-charges-overlay.component.html'
})
export class CallChargesOverlayComponent implements OnInit {

  formData: any;
  constructor(public dialogRef: MatDialogRef<CallChargesOverlayComponent>, @Inject(MAT_DIALOG_DATA) public data, private orderService: OrderService, public auth: OneAuthService, private platformLocation: PlatformLocation) {
    this.platformLocation.onPopState(() => this.dialogRef.close()); }

  ngOnInit() {
    if (this.data != undefined) {
      this.formData = this.data;
      console.log(this.formData)
      this.setIndicatorAsViwed();
    }
  }

  submitEvent(event) {
    this.dialogRef.close({ action: true, data: event });
  }

  closeEvent(event) {
    this.dialogRef.close({ action: event, data: null });
  }

  setIndicatorAsViwed() {
    let trxDocId;
    let trxDocType;
    if (this.formData[0].objData[0].poLineId) {
      trxDocId = this.formData[0].objData[0].poLineId;
      trxDocType = "PO_LINE_CHARGE";
    } else {
      trxDocId = this.formData[0].objData[0].poId
      trxDocType = "PO_CHARGE";
    }
    let reqData = {
      "id": null,
      "trxDocId": trxDocId,
      "trxDocType": trxDocType,
      "viewedPartyBy": "BUYER",
      "viewedBy": this.auth.user.info.orgId,
      "changedPartyBy": "SUPPLIER",
    };
    if (!this.auth.user.info.internalAdmin) {
      this.orderService.setIndicatorAsViwed(reqData).subscribe(res => {
      }, err => {
      });
    }
  }

}
