import { NgModule, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
// import { TranslateService } from 'common-lib';
import { TranslateService } from 'common-lib';
import { RedirectGuard } from './services/redirect-guard';
import { environment } from '@procurant/env/environment';
import { DevLoginComponent } from './modules/dev-login/components/dev-login/dev-login.component';
import { NotFoundComponent } from './modules/core/components/not-found/not-found.component';

export interface IPageTitles {
  name: string;
}



const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'login',
  //   pathMatch: 'full',
  //   data: { title: 'PAGE_TITLES.BUYER' }
  // },
  // {
  //   path: 'login',
  //   component: LoginComponent,
  //   data: { title: 'PAGE_TITLES.BUYER' }
  // },
  // {
  //   path: 'forgot-password',
  //   component: ForgotPasswordComponent,
  //   data: { title: 'PAGE_TITLES.BUYER' }
  // },
  // {
  //   path: 'reset-password',
  //   component: ActivateUserComponent,
  //   data: { title: 'PAGE_TITLES.BUYER' }
  // },
  // {
  //   path: 'app',
  //   component: LayoutComponent,
  //   data: { title: 'PAGE_TITLES.BUYER' },
  //   children: [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: 'dashboard'
  // },
  // {
  //   path: 'dashboard',
  //   redirectTo: 'dashboard'
  // },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: "full",
    data: { title: 'PAGE_TITLES.BUYER' }
  },
  {
    path: 'order',
    loadChildren: () => import('./modules/purchase-order/purchase-order.module').then(m => m.OrderModule),
  },
  //-----------new dashboard design-------------------//
  // {
  //   path: 'dashboard',
  //   loadChildren: './modules/main-dashboard/main-dashboard.module#DashboardModule',
  //   data: { title: 'PAGE_TITLES.DASHBOARD' }
  // },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.BuyerDashboardModule),
    data: { title: 'PAGE_TITLES.DASHBOARD' }
  },
  {
    path: 'task-center',
    loadChildren: () => import('./modules/task-center/task-center.module').then(m => m.TaskCenterModule),
    data: { title: 'PAGE_TITLES.TASK_CENTER' }
  },
  {
    path: 'price-watch',
    loadChildren: () => import('./modules/price-alerts/price-alerts.module').then(m => m.PriceAlertsModule),
    data: { title: 'PAGE_TITLES.PRICE_ALERTS' }
  },
  {
    path: 'track-trace',
    loadChildren: () => import('./modules/track-trace/track-trace.module').then(m => m.TrackTraceModule),
    data: { title: 'PAGE_TITLES.TASK_CENTER' }
  },
  {
    path: 'logistics',
    loadChildren: () => import('./modules/order-logistics/order-logistics.module').then(m => m.LogisticsModule),
    data: { title: 'PAGE_TITLES.ORDER_LOGISTICS' }
  },
  {
    path: 'claims',
    loadChildren: () => import('./modules/order-claims/order-claims.module').then(m => m.OrderClaimsModule),
    data: { title: 'PAGE_TITLES.ORDER_CLAIMS' }
  },
  {
    path: 'demands',
    loadChildren: () => import('./modules/order-demand/order-demand.module').then(m => m.OrderDemandModule),
    data: { title: 'PAGE_TITLES.ORDER_DEMAND' }
  },
  {
    path: 'my-preferences',
    loadChildren: () => import('./modules/my-preferences/my-preferences.module').then(m => m.MyPreferencesModule),
    data: { title: 'PAGE_TITLES.MY_PREFERENCES' }
  },
  {
    path: 'reports-analytics',
    loadChildren: () => import('./modules/reports-analytics/reports-analytics.module').then(m => m.ReportsAnalyticsModule),
    data: { title: 'PAGE_TITLES.REPORT_ANALYTICS' }
  },
  {
    path: 'product-pricing',
    loadChildren: () => import('./modules/product-pricing/product-pricing.module').then(m => m.ProductPricingModule),
    data: { title: 'PAGE_TITLES.SUP_MY_PREFERENCES' }
  },
  {
    path: 'order-inventory',
    loadChildren: () => import('./modules/order-inventory/order-inventory.module').then(m => m.OrderInventoryModule),
    data: { title: 'PAGE_TITLES.SUP_MY_PREFERENCES' }
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/user-profile/user-profile.module').then(m => m.UserProfileModule)
  },
  {
    path: 'order-invoice',
    loadChildren: () => import('./modules/order-invoice/order-invoice.module').then(m => m.InvoiceModule),
  },
  // {
  //   path: '**',
  //   component: NotFoundComponent,
  //   data: { title: 'PAGE_TITLES.BUYER' }
  // },
  {
    path: 'login',
    component: RedirectGuard,
    canActivate: [RedirectGuard],
    data: {
      externalUrl: environment.SUB_DOMAIN + 'login' + environment.DOMAIN + "/login"
    }
  },
  {
    path: 'logout',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.SUB_DOMAIN + 'login' + environment.DOMAIN + "/logout"
    }
  },
  {
    path: 'apps',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.SUB_DOMAIN + 'login' + environment.DOMAIN + "/apps"
    }
  },
  {
    path: 'internal-admin',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.SUB_DOMAIN + 'admin' + environment.DOMAIN + '/internal-admin'
    }
  },
  {
    path: 'connect',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.SUB_DOMAIN + 'connect' + environment.DOMAIN + '/industry-analytics?tab=true'
    }
  },
  {
    path: 'procurant-ask',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      // https://infra1-ask.procurant.io/
      externalUrl: environment.SUB_DOMAIN + 'ask' + environment.DOMAIN
    }
  },

  {
    path: "BBF65054CC9C2C8CB6692B2A1648FD8C",
    component: DevLoginComponent
  },
  {
    path: "dologout",
    component: NotFoundComponent,
    data: {
      externalUrl: environment.SUB_DOMAIN + 'login' + environment.DOMAIN + "/login"
    }
  }
  //   ]
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class CoreRoutingModule {
  constructor(private titleService: Title, private router: Router, private activatedRoute: ActivatedRoute, private translate: TranslateService) {
    // router.events.subscribe((val) => {


    //   console.log(val instanceof NavigationEnd)
    // });

    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd),
    //   map(() => this.activatedRoute),
    //   map((route) => {
    //     while (route.firstChild) {
    //       route = route.firstChild;
    //     };

    //     return route;
    //   }),
    //   filter((route) => route.outlet === 'primary'),
    //   mergeMap((route) => route.data),
    // ).subscribe((event) => this.titleService.setTitle(event['title']));

  }

  public pageTitles: IPageTitles[] = [
    { name: this.translate.instant("PAGE_TITLES.DASHBOARD") },
    { name: this.translate.instant("PAGE_TITLES.TASK_CENTER") },
    { name: this.translate.instant("PAGE_TITLES.PURCHASE_ORDER") }
  ];

}
