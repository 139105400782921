<form [formGroup]="parentForm" [ngClass]="(!clickOnDate)?'custom-date':'header-date'">
  <mat-form-field [floatLabel]="!isMultipleShipDate?'always':'auto'" appearance="outline" [formGroup]="parentForm"
    [ngClass]="(!isMultipleShipDate)?'widthChange':''">
    <mat-label *ngIf="isDisplayLabel">{{placeholder}}</mat-label>
    <input matInput placeholder="{{multipleShipDateRange}}" [matDatepicker]="picker" id="{{ID}}" [min]="minDate"
      [max]="maxDate" formControlName="{{field}}" (dateChange)="dateChangeEvent($event.target.value)"
      (click)="picker.open();closeOverlay()">
    <mat-datepicker #picker></mat-datepicker>
    <mat-datepicker-toggle [ngClass]="{'previousValue':previousValue}" matIconSuffix [for]="picker" (click)="picker.open();closeOverlay()"></mat-datepicker-toggle>
    <div matSuffix *ngIf="previousValue" class="history-icon-container">
      <div position="bottom" kendoTooltip title="{{previousData}}" class="icon-image">
      </div>
    </div>
  </mat-form-field>
  <!-- <mat-error class="trace-error" *ngIf="isTraceError"> {{ 'TRACK_TRACE.MESSAGE.ORDER_START_DATE_ERROR' | translate }}
  </mat-error> -->
</form>
<!-- placeholder="{{!isMultipleShipDate?multipleShipDateRange:placeholder}}" -->
<!-- <form [formGroup]="parentForm" class="calendarWithInputBox">
  <div *ngIf="staticField">
    <mat-form-field appearance="outline" [formGroup]="parentForm">
      <input matInput [matDatepicker]="picker" id="{{ID}}" [min]="minDate" [max]="maxDate"
        placeholder="{{placeholder}} " formControlName="{{field}}" (dateChange)="dateChangeEvent($event.target.value)">
    </mat-form-field>
    <mat-calendar [(selected)]="selectedDate" (selectedChange)="dateChangeEvent($event)"></mat-calendar>
  </div>
</form> -->

<!-- <form [formGroup]="parentForm" *ngIf="!clickOnDate" class="header-date">
  <mat-form-field appearance="outline" [formGroup]="parentForm">
    <mat-label>{{placeholder}}</mat-label>
    <input matInput [matDatepicker]="picker" id="{{ID}}" [min]="minDate" [max]="maxDate" placeholder="{{placeholder}} "
      formControlName="{{field}}" (dateChange)="dateChangeEvent($event.target.value)"
      (click)="picker.open();closeOverlay()">
    <mat-datepicker #picker></mat-datepicker>
    <mat-datepicker-toggle matIconSuffix [for]="picker" (click)="closeOverlay()"></mat-datepicker-toggle>
    <mat-error *ngIf="isTraceError"> {{ 'TRACK_TRACE.MESSAGE.ORDER_START_DATE_ERROR' | translate }}
</mat-error>
  </mat-form-field>
</form> -->